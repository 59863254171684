<template>
  <div class="apartment" v-if="apartment">
    <header class="apartment-images">
      <div class="container-carousel">
        <div class="box-carousel">
          <VueperSlides
            :bullets="false"
            :touchable="false"
            :fixed-height="this.windowWidth < 768 ? '54vh' : '90vh'"
            ref="vueperslides1"
            v-if="apartment.images.length">
            <VueperSlide v-for="(image, index) in apartment.images" :key="index">
              <template v-bind:class="[ image.pathname.split('.')[1]==='mp4' ? 'video-container' : '']" #content>
                <div v-if="image.pathname.split('.')[1]!=='mp4'" class="vueperslide__content-wrapper">
                  <b-img :src="image.largeImageFilepath || null | getAsset" fluid-grow @click="showModal(index)"
                         class="sliderCursor"/>
                </div>
                <video v-if="image.pathname.split('.')[1]==='mp4'" autoplay muted loop playsinline class="sliderCursor"
                       @click="showModal(index)">
                  <source :src="image.pathname | getAsset" type="video/mp4">
                </video>
              </template>
            </VueperSlide>
          </VueperSlides>
        </div>
      </div>
    </header>
    <b-container class="mt-5">
      <b-row>
        <b-col cols="12" lg="8">
          <div class="bg-white">
            <b-row class="border-bottom-gray">
              <b-col>
                <div class="apartment-header">
                  <h1 class="apartment-name">{{ apartment.name }}</h1>
                  <h1 class="apartment-address">
                    {{
                      `${apartment.addressStreet} ${apartment.addressNumber}, ${apartment.addressZipCode} ${apartment.addressCity}`
                    }}
                    <span class="apartment-person" v-for="(person, index) in apartment.numOfPerson" :key="index">
                      <FontAwesomeIcon :icon="['fas', 'user']"/>
                    </span>
                  </h1>
                  <p class="apartment-description">{{ apartment.description }}</p>
                </div>
              </b-col>
            </b-row>
            <b-row class="p-4 p-md-5">
              <b-col cols="12" md="6">
                <div class="apartment-detail">
                  <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                  {{ $t('Floor') }} <span class="content">{{ apartment.floor }}</span>
                </div>
                <div class="apartment-detail">
                  <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                  {{ $t('Size') }} <span class="content">{{ apartment.sqMeters }} m<sup>2</sup></span>
                </div>
                <div class="apartment-detail">
                  <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                  {{ $t('Beds') }} <span class="content">{{ beds.join(', ') }}</span>
                </div>
                <div class="apartment-detail">
                  <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                  {{ $t('Bathroom') }} <span class="content">{{ apartment.numOfBathrooms }}</span>
                </div>
              </b-col>
              <b-col cols="6" v-if="apartment.planImgPath">
                <img class="w-100" :src="apartment.planImgPath | getAsset">
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <GmapMap :center="apartmentPosition" :zoom="13" class="apartment-gmap" ref="map">
                  <GmapMarker :key="apartment.id" :position="apartmentPosition" :icon="apartmentMarker"
                              :clickable="true" @click="openMarkerInfo = true">
                    <GmapInfoWindow v-if="openMarkerInfo"
                                    :position="apartmentPosition"
                                    @closeclick="openMarkerInfo = false"
                                    :options="{ pixelOffset: { width: 0, height: 0 } }">
                      {{ `${apartment.name} - ${apartment.addressStreet}, ${apartment.addressZipCode}` }}
                    </GmapInfoWindow>
                  </GmapMarker>
                </GmapMap>
              </b-col>
            </b-row>
            <b-row class="p-4 p-md-5" v-if="apartment.characteristics && apartment.characteristics.length">
              <b-col cols="12" md="6">
                <h3 class="apartment-characteristics">{{ $t('Building Characteristics') }}</h3>
              </b-col>
              <b-col class="px-3 pt-3 pb-5 px-md-5 py-md-0">
                <div class="apartment-characteristic" v-for="(characteristic, index) in apartment.characteristics"
                     :key="index">
                  <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                  {{ $t(characteristic.translations[$i18n.locale] || characteristic.defaultValue) }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="apartment-amenities" v-if="apartment.amenities && apartment.amenities.length">
                  <img class="apartment-waves" src="@/assets/images/blue_roofs.png">
                  <h1 class="amenities-title">{{ $t('Amenities') }}</h1>
                  <b-row class="px-4 justify-content-center">
                    <b-col class="amenity d-flex align-items-center flex-column" cols="12" md="2"
                           v-for="(amenity, index) in apartment.amenities" :key="index">
                      <img class="amenity-icon" v-if="amenity.iconPath" :src="amenity.iconPath | getAsset"/>
                      <div class="amenity-box" v-else/>
                      <div class="mt-3">{{ $t(amenity.translations[$i18n.locale] || amenity.defaultValue) }}</div>
                    </b-col>
                  </b-row>
                  <p class="amenities-text">{{ $t('Full inventory will be provided after booking is concluded') }}</p>
                </div>
              </b-col>
            </b-row>
            <b-row class="p-4 p-md-5" v-if="apartment.rules && apartment.rules.length">
              <b-col cols="12" md="6">
                <h3 class="apartment-rules">{{ $t('House Rules') }}</h3>
              </b-col>
              <b-col class="px-3 pt-3 pb-5 px-md-5 py-md-0">
                <div class="apartment-rule" v-for="(rule, index) in apartment.rules" :key="index">
                  <FontAwesomeIcon :icon="['fas', 'angle-right']"/>
                  {{ $t(rule.translations[$i18n.locale] || rule.defaultValue) }}
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="12" lg="4" class="mt-4 mt-lg-0">
          <div class="apartment-booking-request">
            <div class="booking-request-header">
              <div class="booking-request-price">
                <p class="starting">{{ $t('starting from') }}</p>
                <div class="price">&euro; {{ apartment.monthlyAmount }}</div>
                {{ `/${$t('month')}` }}
              </div>
            </div>
            <ValidationObserver ref="validator">
              <form class="booking-request-content" @submit.prevent>
                <RFValidation rules="required" name="Check-IN">
                  <RFMonthPicker label="Check-IN" :occuped-periods="apartment.periods.concat(apartment.blockedSlots)"
                                 v-model="checkIn" :min="startDate || currentMonth"
                                 :max="maxCheckInDate ? maxCheckInDate : null" color="#64B7AF"
                                 :special-format="specialDateFormat"/>
                </RFValidation>

                <RFValidation rules="required" name="Check-OUT">
                  <RFMonthPicker label="Check-OUT" :occuped-periods="apartment.periods.concat(apartment.blockedSlots)"
                                 v-model="checkOut" :min="endDate || checkIn ? minStayMonth : nextMonth(checkIn)"
                                 :max="maxDate ? maxDate : null" color="#64B7AF" :ending-date="true"
                                 :special-format="specialDateFormat"/>
                </RFValidation>

                <RFValidation rules="required" name="Guests">
                  <RFInput type="number" :label="$t('Guests')" :min="1" :max="apartment.numOfPerson" v-model="guests"
                           :value="guests" color="#64B7AF" :readonly="apartment.numOfPerson === 1"/>
                </RFValidation>

                <RFButton class="mt-4" :label="$t('Booking Request')" background="#64B7AF" color="#fff" full-width
                          @on-click="bookingApartment"/>
                <p class="content-text">{{ $t('Nothing will be charged at this point') }}</p>
              </form>
            </ValidationObserver>
            <div class="booking-request-footer">
              <div class="footer-text ls-1">{{ `${$t('If you need anything else')},` }}
                <span class="text-bold">{{ `${$t('Get in touch')}!` }}</span>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <CarouselModal/>
  </div>
</template>
<script>
import RFButton from '@/components/forms/RFButton'
import RFInput from '@/components/forms/RFInput'
import RFMonthPicker from '@/components/forms/RFMonthPicker'
import RFValidation from '@/components/forms/RFValidation'

import {accommodations} from '@/enums'
import {apartmentService} from '@/services'
import {mapState} from 'vuex'
import {store} from '@/stores'
import moment from 'moment'
import CarouselModal from '@/components/modals/CarouselModal.vue'
import {getAsset} from '@/helpers'

export default {
  name: 'ApartmentPage',
  components: {
    RFInput,
    RFMonthPicker,
    RFButton,
    RFValidation,
    CarouselModal
  },
  /* beforeRouteEnter(to, from, next) {
     if (!store.state.apartmentStore.apartment) {
       next({ name: 'apartments-page' })
     } else {
       next()
     }
   },*/
  data() {
    return {
      apartment: null,
      apartmentMarker: null,
      openMarkerInfo: false,
      checkIn: null,
      checkOut: null,
      guests: null,
      maxDate: null,
      maxCheckInDate: null,
      specialDateFormat: true,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...mapState({
      startDate: state => state.searchStore.searchParams.startDate,
      endDate: state => state.searchStore.searchParams.endDate,
    }),
    sliderHeight() {
      return `${(window.innerHeight * 0.01) - 86}px`
    },
    apartmentPosition() {
      return {
        lat: this.apartment.addressLatitude,
        lng: this.apartment.addressLongitude,
      }
    },
    currentMonth() {
      return moment().format('YYYY-MM')
    },
    beds() {
      return Object.entries(this.apartment.beds).map(entry => (`${entry[1]} ${entry[0]}`))
    },
    minStayMonth() {
      let addMonthCount = (this.apartment.minStayMonth != null) ? this.apartment.minStayMonth - 1 : 1
      return this.checkIn ? moment(this.checkIn).add(addMonthCount, 'months').format('YYYY-MM') : null
    }
  },
  async beforeMount() {
    if (store.state.apartmentStore.apartment && store.state.apartmentStore.apartment.name.replace(/\s/g, "").toUpperCase() === this.$route.params.name.toUpperCase()) {
      this.apartment = store.state.apartmentStore.apartment
    } else {
      const {data: apartment} = await apartmentService.getApartmentByName(this.$route.params.name.replace(/[\s'[\]]/g, "").toUpperCase())
      this.apartment = apartment
      this.apartment.images.sort((a, b) => a.orderIndex - b.orderIndex);
      this.$store.commit('apartmentStore/setApartment', this.apartment)
    }

    this.setMaxDates()

    this.guests = this.apartment.numOfPerson
    this.checkIn = this.startDate
    this.checkOut = this.endDate

    this.$nextTick(() => {
      this.$refs.map.$mapPromise.then(() => {
        this.apartmentMarker = {
          url: '/images/green_roof.png',
          scaledSize: new google.maps.Size(30, 20),
        }
      })
    })
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

    console.log(this.apartment)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  /*destroyed() {
    this.$store.commit('searchStore/setParams', null)
  },*/
  methods: {
    async bookingApartment() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        const checkIn = moment(this.checkIn, 'YYYY-MM')
        const checkOut = moment(this.checkOut, 'YYYY-MM')

        if (!checkIn.isSame(checkOut) && !checkOut.isAfter(checkIn)) {
          this.$toasted.error(this.$i18n.t('Invalid dates'))
          return false
        }

        const reservation = {
          item: this.apartment,
          type: accommodations.APARTMENT,
          checkIn: this.checkIn,
          checkOut: this.checkOut,
          guests: this.guests > this.apartment.numOfPerson ? this.apartment.numOfPerson : this.guests,
        }

        this.$store.commit('reservationStore/setReservation', reservation)
        this.$router.push('/booking-request')
      }
    },
    nextMonth(date) {
      return date ? moment(date, 'YYYY-MM').add(1, 'month').format('YYYY-MM') : moment().format('YYYY-MM')
    },
    checkIfOccupedPeriod() {
      const occuped = this.apartment.periods.find(period => moment(period.date).isBetween(this.checkIn, this.checkOut))

      if (occuped) {
        this.$toasted.error(this.$i18n.t('Invalid dates'))

        this.$nextTick(() => {
          this.checkIn = null
          this.checkOut = null
        })

        return false
      }
    },
    showModal(index) {
      this.$store.commit('carouselStore/setIndexSlide', index)
      this.$store.commit('carouselStore/setImagesList', this.apartment.images)
      this.$root.$emit('bv::show::modal', 'carousel-modal')
    },
    setMaxDates() {
      //CHECK OUT : 2 años despues de hoy
      let currentDate = new Date()
      let currentDateYear = currentDate.getFullYear()
      let currentDateMonth = currentDate.getMonth()
      let currentDateDay = currentDate.getDate()
      let maxCheckoutDate = new Date(currentDateYear + 2, currentDateMonth, currentDateDay)
      this.maxDate = maxCheckoutDate.toISOString().split("T")[0]

      //CHECK IN : Cantidad de meses de estadia minima antes del check out
      let maxCheckOutDateYear = maxCheckoutDate.getFullYear()
      let maxCheckOutDateMonth = maxCheckoutDate.getMonth()

      //Si el minStayMonth no es null, se le resta al mes del maxCheckoutDate para la calcular el mes de maxCheckInDate. Sino, es toma este como 1 mes antes del mes de maxCheckoutDate
      let subtractMonthCount = (this.apartment.minStayMonth != null) ? this.apartment.minStayMonth - 1 : 1
      let maxCheckInDateAndTime = new Date(maxCheckOutDateYear, maxCheckOutDateMonth - subtractMonthCount, 1)
      this.maxCheckInDate = maxCheckInDateAndTime.toISOString().split("T")[0]

    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
  },
  watch: {
    checkIn: {
      handler() {
        this.checkIfOccupedPeriod()
        // if (this.occupedPeriods) {
        //   this.index = this.occupedPeriods.findIndex(period => {
        //     const date = moment(period.date, 'YYYY-MM')
        //     return moment(this.checkIn).isBefore(date)
        //   })

        //   this.maxDate = this.occupedPeriods[this.index].date
        // }
      }
    },
    checkOut: {
      handler() {
        this.checkIfOccupedPeriod()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.container-carousel {
  position: relative;
}

.box-carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-carousel {
  top: 80vh;
  z-index: 9;
  margin-bottom: 20px; /* for demo purpose  */
}

.thumbnails {
  margin: auto;
  max-width: 1000px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}

.apartment {

  .video-wrapper {
    /* Will not allow the video to overflow the
    container */
    overflow: hidden;

    /* Centering the container's content vertically
    and horizontally */
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  video {
    /** Simulationg background-size: cover */
    object-fit: cover;
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;
  }

  background-color: $gray;
  padding-bottom: 50px;

  .apartment-images {
    height: 50vh;

    .vueperslides {
      height: 100%;
    }
  }

  .border-bottom-gray {
    border-bottom: 1px solid $gray;
  }

  .apartment-header {
    padding: 30px 20px;

    .apartment-name {
      @include font-style($montserrat, 'semibold', $font-24);
      color: $green;
      text-transform: uppercase;
    }

    .apartment-address {
      @include font-style($montserrat, 'medium', $font-16);
      color: $green;
    }

    .apartment-person {
      font-size: $font-14;
      color: $dark-blue;
      margin: 0 5px;
    }

    .apartment-description {
      @include font-style('hind-light', 'light', $font-14);
      margin: 50px 0 0 0;
    }
  }

  .apartment-detail,
  .apartment-characteristic,
  .apartment-rule {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: $green;

    .content {
      color: $dark-blue;
    }
  }

  .apartment-gmap {
    height: 30vh;
  }

  .apartment-characteristics,
  .apartment-rules {
    @include font-style($montserrat, 'medium', $font-20);
    color: $green;
    text-transform: uppercase;
  }

  .apartment-amenities {
    background-color: $green;
    position: relative;
    padding-bottom: 5px;

    .apartment-waves {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translate(-50%, -48%);
      width: 50%;
    }

    .amenities-title {
      @include font-style($montserrat, 'medium', $font-20);
      color: $white;
      text-transform: uppercase;
      text-align: center;
      padding-top: 50px;
      margin-bottom: 50px;
    }

    .amenity {
      @include font-style($montserrat, 'medium', $font-14);
      color: $white;
      text-align: center;
      margin-bottom: 40px;

      .amenity-icon {
        width: 45px;
      }

      .amenity-box {
        width: 45px;
        height: 45px;
        border: 1px solid $white;
      }
    }

    .amenities-text {
      @include font-style($montserrat, 'medium', $font-14);
      color: $white;
      text-align: center;
      padding: 20px;
    }
  }

  .apartment-booking-request {
    background-color: $white;

    .booking-request-header {
      background-color: $green;
      padding: 10px 20px;

      .starting {
        margin: 0;
      }

      .booking-request-price {
        @include font-style($montserrat, 'semibold', $font-18);
        color: $white;

        .price {
          @include font-style($montserrat-bold, 'bold', $font-24);
          display: inline-block;
        }
      }
    }

    .booking-request-content {
      padding: 10px 20px;

      .content-text {
        @include font-style($montserrat, 'semibold', $font-10);
        text-align: center;
        color: $green;
        margin-top: 10px;
      }
    }

    .booking-request-footer {
      background-color: $dark-blue;
      padding: 20px;

      .footer-text {
        @include font-style('hind-light', 'light', $font-10);
        text-transform: uppercase;
        color: $white;
        text-align: center;

        .text-bold {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .apartment {
    .apartment-images {
      height: calc(100vh - 86px);
    }

    .apartment-header {
      padding: 30px 50px;
    }

    .apartment-gmap {
      height: 50vh;
    }

    .apartment-amenities {
      .apartment-waves {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -47%);
        width: 25%;
      }
    }
  }
}
</style>
